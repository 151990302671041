import React from "react";
import HRFormAddEmployee from "../../components/hr/HRFormAddEmployee";
import HRSideBar from "../../components/hr/HRSideBar";

const AddEmployee = () => {
  return <>
  <HRFormAddEmployee />
  </>;
};

export default AddEmployee;
