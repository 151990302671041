import React from "react"

const UserLogs = () => {
    return(
        <div className="user-logs-div">
            <h1> User Logs</h1>
        </div>
    )
}

export default UserLogs