import React from "react";
import HRFormEditEmployee from "../../components/hr/HRFormEditEmployee";
import HRSideBar from "../../components/hr/HRSideBar";

const EditEmployee = () => {
    return(
        <>
        <HRFormEditEmployee />
        </>
    )
}

export default EditEmployee