const TicketsLandingPage = () => {
  return (
    <div className="min-h-screen flex flex-col justify-center items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 614.24714 528.65003"
        className="w-72"
      >
        <path
          d="M489.06465,258.67168c-4.93763-26.4263,2.83614-56.49071,24.44276-72.48657a123.86324,123.86324,0,0,0,25.82184,81.0254c6.93546,8.88938,15.575,18.05438,15.3066,29.32608-.16685,7.01352-3.95987,13.54729-8.95834,18.46962-4.99885,4.92244-11.15635,8.4713-17.24023,11.96495l-.947,1.47318C509.65591,308.328,494.00227,285.098,489.06465,258.67168Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#f0f0f0"
        />
        <path
          d="M513.9011,186.5891a105.86983,105.86983,0,0,0-6.98908,64.76136,45.59136,45.59136,0,0,0,4.82768,13.44405,26.14858,26.14858,0,0,0,9.87837,9.58161c3.77061,2.17874,7.87983,3.9707,11.05516,7.02808a16.01085,16.01085,0,0,1,4.71318,12.21634c-.152,5.62259-2.31531,10.85523-4.60862,15.8988-2.5463,5.59992-5.27982,11.37615-5.11468,17.672.02.76283-1.16482.74927-1.1848-.01241-.28731-10.95373,7.6319-19.75079,9.39647-30.26133.82338-4.90441.17374-10.08666-3.27429-13.88828-3.01516-3.32434-7.25105-5.17585-11.08793-7.34888a27.90244,27.90244,0,0,1-10.008-9.00887,42.14771,42.14771,0,0,1-5.32674-13.20884,102.71242,102.71242,0,0,1-2.32806-32.01772,107.74713,107.74713,0,0,1,9.03456-35.46463c.31048-.69431,1.32523-.08107,1.01677.6087Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#fff"
        />
        <path
          d="M505.03917,244.42165a15.88383,15.88383,0,0,1-16.57127-12.18326c-.17318-.74241.97237-1.04641,1.14578-.303a14.70808,14.70808,0,0,0,15.4379,11.30147c.76028-.05715.74352,1.128-.01241,1.18479Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#fff"
        />
        <path
          d="M519.56073,273.52321a30.61483,30.61483,0,0,0,7.6736-20.95005c-.01731-.76288,1.16751-.74955,1.1848.0124a31.844,31.844,0,0,1-8.0294,21.7842c-.5077.57079-1.33395-.27886-.829-.84655Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#fff"
        />
        <path
          d="M506.205,209.93055a8.991,8.991,0,0,0,7.98705-2.99869c.50022-.577,1.326.27336.829.84655a10.07516,10.07516,0,0,1-8.82846,3.33694.61233.61233,0,0,1-.5862-.5986.59543.59543,0,0,1,.5986-.5862Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#fff"
        />
        <path
          d="M601.86634,208.04366c-.3021.36914-.60419.73828-.90372,1.1171a118.40568,118.40568,0,0,0-10.837,15.85813c-.25247.4268-.5019.86314-.74157,1.29631a124.827,124.827,0,0,0-14.56489,43.58858,121.22208,121.22208,0,0,0-1.1114,18.385c.13349,8.49015.95374,17.685-1.6487,25.49357a20.79625,20.79625,0,0,1-.93652,2.39744l-41.76165,13.30563c-.11009-.01739-.21761-.02516-.32816-.0424l-1.64313.6075c-.02711-.31672-.04842-.64578-.07553-.9625-.01649-.18365-.02368-.37027-.04017-.55392-.00805-.12338-.01565-.247-.03-.35783-.00283-.041-.00519-.08226-.005-.11384-.01432-.11087-.01584-.21537-.02712-.31671-.12421-1.83906-.23913-3.68114-.33449-5.52939-.003-.00954-.003-.00954.00322-.022-.7168-14.07671-.26472-28.33693,2.33742-42.07519.07843-.41331.15335-.836.25085-1.25539a115.67539,115.67539,0,0,1,5.11549-18.17043,102.26,102.26,0,0,1,4.12386-9.4897,85.03657,85.03657,0,0,1,15.43659-21.64774c12.4587-12.68053,28.83417-21.23539,46.38074-21.50476C600.97441,208.04451,601.41443,208.04069,601.86634,208.04366Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#f0f0f0"
        />
        <path
          d="M601.94133,208.60361A105.86977,105.86977,0,0,0,557.37014,256.104a45.5906,45.5906,0,0,0-4.23962,13.64092,26.14853,26.14853,0,0,0,2.11853,13.59783c1.69887,4.00977,3.901,7.91459,4.59553,12.2675a16.01084,16.01084,0,0,1-3.59186,12.59173c-3.50659,4.39778-8.38425,7.27332-13.25191,9.91959-5.40461,2.93818-11.06486,5.9044-14.72356,11.03072-.4433.62113-1.38115-.103-.93852-.72323,6.3655-8.91892,17.985-11.175,25.722-18.50465,3.61022-3.42017,6.2116-7.949,5.74737-13.06037-.40595-4.46963-2.67333-8.49826-4.42856-12.54337a27.9024,27.9024,0,0,1-2.56685-13.21857,42.1476,42.1476,0,0,1,3.69953-13.75359,102.71244,102.71244,0,0,1,17.41805-26.966A107.7471,107.7471,0,0,1,601.496,207.50543c.66593-.36744,1.10694.73315.44536,1.09818Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#fff"
        />
        <path
          d="M560.04639,249.44417a15.88383,15.88383,0,0,1-5.89607-19.7047c.30871-.697,1.40639-.25007,1.09727.4479a14.70809,14.70809,0,0,0,5.522,18.31828c.64145.41211-.08546,1.34828-.72323.93852Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#fff"
        />
        <path
          d="M554.11989,281.42311a30.61484,30.61484,0,0,0,18.74032-12.1074c.44549-.61954,1.38347.10445.93853.72323a31.844,31.844,0,0,1-19.52662,12.55921c-.749.15008-.89719-1.02578-.15223-1.175Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#fff"
        />
        <path
          d="M581.74329,222.60689a8.991,8.991,0,0,0,8.18263,2.41447c.74676-.1595.89413,1.01658.15224,1.175a10.07517,10.07517,0,0,1-9.0581-2.651.61234.61234,0,0,1-.10765-.83089.59542.59542,0,0,1,.83088-.10764Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#fff"
        />
        <path
          d="M727.45024,386.00273a9.45151,9.45151,0,0,1,.21487,1.47266l38.57107,22.2515,9.37525-5.39716L785.606,417.414,769.9375,428.58158a7.18313,7.18313,0,0,1-8.61813-.21L721.546,396.9941a9.42524,9.42524,0,1,1,5.90428-10.99137Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#ffb6b6"
        />
        <path
          d="M766.77166,407.27011a4.03615,4.03615,0,0,1,2.01514-2.69421l17.80755-9.70255a11.2213,11.2213,0,0,1,13.56639,17.87792l-14.27229,14.499a4.04052,4.04052,0,0,1-6.10514-.4016l-12.28814-16.29234A4.036,4.036,0,0,1,766.77166,407.27011Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#3f3d56"
        />
        <path
          d="M797.78041,351.65092c2.56215-8.16547,8.38856-15.65455,16.44635-18.41493,8.05827-2.76037,19.8576,1.44458,25.91561,7.48209,11.08127,11.04382,11.42231,24.56342,3.809,36.55274-1.51431-.08015-6.73962-.13944-8.27227-.19684L833.511,369.8477v7.15505c-11.96283-.3697-24.41723-.13822-36.87838.10116C795.51571,368.61534,795.21826,359.81639,797.78041,351.65092Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#2f2e41"
        />
        <polygon
          points="502.281 507.932 491.37 507.932 486.179 465.845 502.283 465.846 502.281 507.932"
          fill="#ffb6b6"
        />
        <polygon
          points="569.528 507.932 558.617 507.932 553.426 465.845 569.53 465.846 569.528 507.932"
          fill="#ffb6b6"
        />
        <polygon
          points="537.668 249.442 540.14 255.87 539.646 262.299 491.259 279.058 493.66 250.926 499.099 244.498 537.668 249.442"
          fill="#ffb6b6"
        />
        <circle cx="522.36888" cy="174.31461" r="17.28172" fill="#ffb7b7" />
        <path
          d="M841.37788,464.61289V475.5963s12.85615,16.70676,5.93361,29.56291L867.14676,676.251H846.37913L811.70984,531.86045,796.9324,673.28422H779.13157l-2.1222-112.47687a239.05247,239.05247,0,0,1,14.56725-86.79921l0-.00005Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#2f2e41"
        />
        <path
          d="M803.37069,337.48574A21.70637,21.70637,0,0,1,836.372,356.42966c-9.66.44425-19.80767.5599-30.09732.57988l-2.43178-5.9516-1.43366,5.956q-4.703-.00045-9.42385-.01237A22.16336,22.16336,0,0,1,803.37069,337.48574Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#2f2e41"
        />
        <path
          d="M800.03827,714.325H792.2958l-1.382-7.30982-3.5395,7.30982H766.83942a4.61606,4.61606,0,0,1-2.623-8.41464L780.615,694.585v-7.39l17.24842,1.0295Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#2f2e41"
        />
        <path
          d="M868.27476,714.325h-7.74247l-1.382-7.30982-3.5395,7.30982H835.07592a4.61606,4.61606,0,0,1-2.623-8.41464L848.85147,694.585v-7.39l17.24841,1.0295Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#2f2e41"
        />
        <path
          d="M849.2407,499.83848a134.7058,134.7058,0,0,0-2.93757-39.38028c-3.67033-16.29464-8.33459-37.02845-8.425-37.56438a.19946.19946,0,0,1-.00278-.03174l-1.42171-30.37091a.351.351,0,0,0-.33881-.33437l-9.83314-.33052a.351.351,0,0,1-.33919-.35079v0a5.57828,5.57828,0,0,0-5.57829-5.57828H800.72491a5.22274,5.22274,0,0,0-5.17988,4.57191.34673.34673,0,0,1-.35288.312l-7.64219-.25686a.351.351,0,0,0-.34658.24542l-.53158,1.68889a176.94738,176.94738,0,0,0-6.27367,76.8496c.92543,6.85665.50577,17.16646.15574,22.91022a4.27118,4.27118,0,0,0,4.02009,4.52729c14.20708.81747,54.43317,20.28092,64.06364,10.9885C848.66084,507.71177,848.91726,504.28384,849.2407,499.83848Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#3f3d56"
        />
        <path
          d="M770.45153,386.00273a9.45151,9.45151,0,0,1,.21487,1.47266l38.57107,22.2515,9.37525-5.39716,9.99456,13.0843-15.66849,11.16755a7.18313,7.18313,0,0,1-8.61813-.21L764.54725,396.9941a9.42524,9.42524,0,1,1,5.90428-10.99137Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#ffb6b6"
        />
        <path
          d="M809.773,407.27011a4.03615,4.03615,0,0,1,2.01514-2.69421l17.80755-9.70255a11.22131,11.22131,0,0,1,13.5664,17.87792l-14.2723,14.499a4.04052,4.04052,0,0,1-6.10514-.4016l-12.28814-16.29234A4.036,4.036,0,0,1,809.773,407.27011Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#3f3d56"
        />
        <circle cx="45" cy="423.69547" r="45" fill="#f0f0f0" />
        <path
          d="M735.529,453.21515,409.42085,602.93776a19.27705,19.27705,0,0,1-25.53338-9.465L303.412,418.19049a19.27736,19.27736,0,0,1,9.465-25.53338L638.98509,242.9345a19.27735,19.27735,0,0,1,25.53337,9.465L744.994,427.68177A19.27705,19.27705,0,0,1,735.529,453.21515Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#fff"
        />
        <path
          d="M318.30109,404.47143a6.26246,6.26246,0,0,0-3.07482,8.29487L395.7018,588.04862a6.26245,6.26245,0,0,0,8.29486,3.07482L730.10484,441.40082a6.26245,6.26245,0,0,0,3.07482-8.29486L652.70414,257.82364a6.26246,6.26246,0,0,0-8.29487-3.07482Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#e4e4e4"
        />
        <path
          d="M735.529,453.21515,409.42085,602.93776a19.27705,19.27705,0,0,1-25.53338-9.465L303.412,418.19049a19.27736,19.27736,0,0,1,9.465-25.53338L638.98509,242.9345a19.27735,19.27735,0,0,1,25.53337,9.465L744.994,427.68177A19.27705,19.27705,0,0,1,735.529,453.21515ZM313.7114,394.4747a17.27491,17.27491,0,0,0-8.48186,22.8813l80.47552,175.28231a17.27491,17.27491,0,0,0,22.8813,8.48186L734.69454,451.39756a17.27492,17.27492,0,0,0,8.48186-22.8813L662.70087,253.234a17.2749,17.2749,0,0,0-22.88129-8.48186Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#cacaca"
        />
        <path
          d="M654.04246,373.67481,394.96915,492.62041a11.14657,11.14657,0,0,1-12.60483-2.34253l-75.79959-77.7327a11.12377,11.12377,0,0,1,3.30857-17.86861l332.018-153.05436a11.12474,11.12474,0,0,1,15.70523,8.81986l.0446-.00114,2.88018,112.84155A11.11021,11.11021,0,0,1,654.04246,373.67481ZM642.74,243.43328,310.71024,396.493a9.1235,9.1235,0,0,0-2.71376,14.65549L383.796,488.88128a9.142,9.142,0,0,0,10.3387,1.92154L653.208,371.85723a9.11259,9.11259,0,0,0,5.31384-8.5239l-2.85465-111.84156.3995-.00988-.3995.00988A9.12386,9.12386,0,0,0,642.74,243.43328Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#cacaca"
        />
        <path
          d="M491.58023,534.58773l-80.71652,37.05853a4.90689,4.90689,0,1,1-4.09475-8.9187L487.48548,525.669a4.90689,4.90689,0,0,1,4.09475,8.9187Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#fff"
        />
        <path
          d="M550.63063,420.05309a25.22024,25.22024,0,1,1-45.84,21.046"
          transform="translate(-292.87643 -185.67498)"
          fill="#cacaca"
        />
        <path
          d="M732.62106,691.62827H373.78488a19.277,19.277,0,0,1-19.25537-19.25537V479.49936A19.27735,19.27735,0,0,1,373.78488,460.244H732.62106a19.27735,19.27735,0,0,1,19.25537,19.25537V672.3729A19.277,19.277,0,0,1,732.62106,691.62827Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#fff"
        />
        <path
          d="M373.78488,473.244a6.26246,6.26246,0,0,0-6.25537,6.25537V672.3729a6.26246,6.26246,0,0,0,6.25537,6.25537H732.62106a6.26246,6.26246,0,0,0,6.25537-6.25537V479.49936a6.26246,6.26246,0,0,0-6.25537-6.25537Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#363636"
        />
        <path
          d="M732.62106,691.62827H373.78488a19.277,19.277,0,0,1-19.25537-19.25537V479.49936A19.27735,19.27735,0,0,1,373.78488,460.244H732.62106a19.27735,19.27735,0,0,1,19.25537,19.25537V672.3729A19.277,19.277,0,0,1,732.62106,691.62827ZM373.78488,462.244a17.2749,17.2749,0,0,0-17.25537,17.25537V672.3729a17.2749,17.2749,0,0,0,17.25537,17.25537H732.62106a17.2749,17.2749,0,0,0,17.25537-17.25537V479.49936A17.2749,17.2749,0,0,0,732.62106,462.244Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#3f3d56"
        />
        <path
          d="M691.75436,585.34262H406.68063a11.14658,11.14658,0,0,1-10.47778-7.38818l-36.45264-102.27a11.12377,11.12377,0,0,1,10.4624-14.8584l365.59717-.562a11.12473,11.12473,0,0,1,10.59277,14.56836l.041.01758L701.97848,578.60141A11.11021,11.11021,0,0,1,691.75436,585.34262ZM735.8254,462.264l-365.6101.562a9.12351,9.12351,0,0,0-8.58118,12.18653l36.45251,102.27a9.142,9.142,0,0,0,8.594,6.06006H691.75436a9.11257,9.11257,0,0,0,8.38574-5.52929l44.07105-102.832.36718.15771-.36718-.15771A9.12385,9.12385,0,0,0,735.8254,462.264Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#3f3d56"
        />
        <path
          d="M476.96954,663.79276H388.15236a4.90689,4.90689,0,1,1,0-9.81378h88.81718a4.90689,4.90689,0,1,1,0,9.81378Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#fff"
        />
        <path
          d="M578.42321,584.34286a25.22024,25.22024,0,0,1-50.44048,0"
          transform="translate(-292.87643 -185.67498)"
          fill="#3f3d56"
        />
        <circle cx="126" cy="99.69547" r="9" fill="#f0f0f0" />
        <circle cx="9" cy="315.69547" r="9" fill="#f0f0f0" />
        <circle cx="459" cy="146.69547" r="9" fill="#f0f0f0" />
        <path
          d="M905.93289,714.325h-171.294a1.19068,1.19068,0,0,1,0-2.38137h171.294a1.19069,1.19069,0,0,1,0,2.38137Z"
          transform="translate(-292.87643 -185.67498)"
          fill="#cacaca"
        />
      </svg>
      
      <p>Select a message</p>
    </div>
  );
};

export default TicketsLandingPage;
